<script setup lang="ts" generic="T extends boolean | string">
import { computed } from 'vue';

interface Props {
	modelValue: T;
	name: string;
	options?: Array<string>;
	checkedIcon?: string;
	uncheckedIcon?: string;
}

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const checked = computed(() => props.modelValue === props.options?.[1] || props.modelValue === true);

function updateModel (state: boolean): void {
	emit('update:modelValue', props.options?.[Number(state)] ?? state);
}
</script>

<template>
	<label :for="name" class="toggleInput">
		<input
			type="checkbox"
			:id="name"
			:name
			:checked
			@input="updateModel(($event.target as HTMLInputElement).checked)"
		>
		<span
			class="slide icon"
			:data-checked="checkedIcon"
			:data-unchecked="uncheckedIcon"
		></span>
	</label>
</template>

<style scoped>
.toggleInput {
	display: block;
	width: 2.75em;
	height: 1.5em;
	border: 1px solid var(--color-border-light);
	border-radius: 1em;
	overflow: hidden;
	cursor: pointer;
	user-select: none;
}

.toggleInput > input {
	position: absolute;
	opacity: 0;
}

.toggleInput > .slide {
	display: block;
	height: 100%;
	aspect-ratio: 1;
	margin-left: 0;
	transform: translateX(0%);
	transition: 0.1s ease margin-left, 0.1s ease transform;
}
.toggleInput > input:checked + .slide {
	margin-left: 100%;
	transform: translateX(-100%);
}
.toggleInput > .slide::before {
	content: '';
	position: absolute;
	right: 50%;
	display: block;
	width: 200%;
	height: 100%;
	background-color: var(--color-primary);
	opacity: 0;
	transition: 0s linear opacity;
	transition-delay: 0.1s;
}
.toggleInput > input:checked + .slide::before {
	opacity: 1;
	transition-delay: 0s;
}
.toggleInput > .slide::after {
	content: attr(data-unchecked);
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	font-family: inherit;
	line-height: 1;
	text-align: center;
	background-color: white;
	border: 1px solid #eee;
	border-radius: 50%;
}
.toggleInput > input:checked + .slide::after {
	content: attr(data-checked);
}
</style>
