<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { BoxModel } from 'box-model';
import { offlineProject } from '@/main';

const boxModelDisplay = ref<HTMLDivElement | null>(null);
let canvas = document.querySelector('.canvasPanelInner .output');
const projectReference = ref(offlineProject);
let model: BoxModel;
let observer: MutationObserver;
const showModel = ref(false);

function observe (): void {
	if (!canvas?.shadowRoot) return;
	observer = new MutationObserver(update);
	observer.observe(canvas.shadowRoot, { attributes: false, childList: true, subtree: true });
}

function update (): void {
	if (projectReference.value.focusedSelector) {
		const element = canvas?.shadowRoot?.querySelector(projectReference.value.focusedSelector);
		if (element instanceof HTMLElement) {
			model?.updateFromElement(element);
			showModel.value = true;
			return;
		}
	}
	showModel.value = false;
	window.setTimeout(update, 500);
}

watch(() => projectReference.value.focusedSelector, update);
watch(() => projectReference.value.css, update);

onMounted(() => {
	canvas = document.querySelector('.canvasPanelInner .output');
	if (boxModelDisplay.value) {
		model = new BoxModel(boxModelDisplay.value);
		model.allowOverlap = true;
		model.live = true;
		model.showLabels = false;
		update();
		observe();
	}
});

onUnmounted(() => {
	observer?.disconnect();
});
</script>

<template>
	<div class="boxModel">
		<div v-if="projectReference.focusedSelector" class="selector">{{ projectReference.focusedSelector }}</div>
		<div v-else class="selector placeholder"><i>Select an element in the editor to display its box model.</i></div>
		<div class="output" aria-hidden="true">
			<div
				ref="boxModelDisplay"
				class="inner"
				:style="{
					opacity: showModel ? '1' : '0',
					transform: `scale(${projectReference.zoom * 100}%)`
				}"
			></div>
		</div>
		<ul class="key">
			<li><span style="background: #87b2bc;"></span>&nbsp;&nbsp;Content</li>
			<li><span style="background: #b7c47f;"></span>&nbsp;&nbsp;Padding</li>
			<li><span style="background: #e3c381;"></span>&nbsp;&nbsp;Border</li>
			<li><span style="background: #ad8052;"></span>&nbsp;&nbsp;Margin</li>
		</ul>
	</div>
</template>

<style scoped>
.boxModel {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 0.5rem;
	overflow: hidden;
	user-select: none;
}

.boxModel > .selector {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	padding: 2ch 2.5ch 1ch;
	font-family: monospace;
	font-size: 0.8rem;
	transition: 0.1s ease opacity;

	&.placeholder {
		color: grey;
	}
}

.boxModel > .output {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: var(--color-background);
	border-radius: var(--border-radius-large);
	overflow: hidden;
}

.boxModel > .output > .inner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: 0.1s ease opacity;
	overflow: hidden;
}

.boxModel > ul.key {
	position: absolute;
	z-index: 1;
	bottom: 0;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 1ch 5ch;
	width: 100%;
	margin: 0;
	padding: 1ch 2ch 2ch;
	list-style: none;
	font-size: 0.8rem;
	background-color: inherit;
}
.boxModel > ul.key li {
	margin: 0;
	white-space: nowrap;
}
.boxModel > ul.key li span {
	display: inline-block;
	width: 1em;
	height: 1em;
	border: 1px solid var(--color-border);
	transform: translateY(0.1em);
}

@media only screen and (max-width: 700px) {
	.boxModel {
		padding: 0.25rem;
	}
}
</style>

<style>
.CascadesBoxModelMargin {
	display: grid;
	place-items: center;
	background: #ad8052;
}

.CascadesBoxModelPadding {
	display: grid;
	place-items: center;
	font: inherit;
	background: #b7c47f;
	border-color: #e3c381 !important;
}

.CascadesBoxModelContent {
	display: grid;
	place-items: center;
	color: transparent !important;
	font: inherit;
	background: #87b2bc;
}
</style>
