<template>
	<div id="refreshPrompt" data-journey-id="RefreshPrompt">
		<img src="/logo.svg" width="30" height="30" alt="">
		<p>An update is available.</p>
		<button class="refresh" data-journey-id="RefreshPromptRefresh">Refresh</button>
		<button
			class="close"
			onclick="document.getElementById('refreshPrompt')?.classList.remove('show')"
			data-journey-id="RefreshPromptClose"
		><span class="icon">close</span></button>
	</div>
</template>

<style scoped>
#refreshPrompt {
	position: fixed;
	z-index: 100;
	right: 10px;
	bottom: 10px;
	display: none;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	padding: 0.7ch 0.7ch 0.7ch 1.4ch;
	color: white;
	background-color: var(--color-darkgrey);
	border-radius: var(--border-radius);
	box-shadow: 0 4px 13px -5px #0006;
	animation: refreshPromptFadeIn 0.5s ease forwards;
	pointer-events: none;
}
#refreshPrompt.show {
	display: flex;
	pointer-events: all;
}
#refreshPrompt img {
	width: 1.875em;
	height: 1.875em;
}
#refreshPrompt p {
	margin: 0 0.5em;
}
#refreshPrompt .refresh {
	padding-inline: 1ch;
}
@keyframes refreshPromptFadeIn {
	0%   { transform: translateY(100%);opacity: 0; }
	100% { transform: translateY(0%);opacity: 1; }
}
</style>
