<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { dialogStore, offlineProject } from '@/main';
import { projectDefaults } from '@/structures';
import BoxModelPanel from '@/components/BoxModelPanel.vue';
import Resizer from '@/components/Resizer.vue';
import Editor from '@/components/Editor.vue';
import EditorWizard from '@/components/EditorWizard.vue';
import CanvasPanel from '@/components/CanvasPanel.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const canvasPanelRatio = { min: 0.2, max: 1.8 };
const editorPanelRatio = { min: 0.2, max: 1.8 };
const downloadAvailable = computed(() => 'showSaveFilePicker' in window);
const projectReference = ref(offlineProject);
const resizing = ref(false);
const showBoxModel = ref(true);
const verticalEditorArrangement = ref(false);
let mobileQuery: MediaQueryList;

async function createNewProject (force = false): Promise<void> {
	let confirmation = true;
	if (!force && projectReference.value.css.length > 0) {
		confirmation = window.confirm('You have unsaved changes. Are you sure you want to create a new project?');
	}
	if (!confirmation) return;
	projectReference.value.css = '';
}

async function downloadProject (): Promise<void> {
	// @ts-expect-error: This API is not available in every browser yet.
	const newHandle = await window.showSaveFilePicker({ suggestedName: `${projectReference.value.title}.css` });
	const writableStream = await newHandle.createWritable();
	await writableStream.write(new Blob([projectReference.value.css]));
	await writableStream.close();
}

function updateResizing (value: boolean): void {
	resizing.value = value;
}

function updateMobileArrangement (query: MediaQueryList | MediaQueryListEvent): void {
	verticalEditorArrangement.value = query.matches;
}

function handleKeybinds (event: KeyboardEvent): void {
	if (event.code === 'KeyS' && (event.ctrlKey || event.metaKey)) {
		event.preventDefault();
	}
}

onMounted(() => {
	window.addEventListener('keydown', handleKeybinds);
	// Arrange the editor vertically if the viewport width is too small.
	mobileQuery = window.matchMedia('only screen and (max-width: 700px)');
	updateMobileArrangement(mobileQuery);
	mobileQuery.addEventListener('change', updateMobileArrangement);
});

onUnmounted(() => {
	window.removeEventListener('keydown', handleKeybinds);
	mobileQuery.removeEventListener('change', updateMobileArrangement);
});
</script>

<template>
	<main>
		<nav>
			<a href="https://cascades.app/" target="_blank">
				<img class="logo" src="@/assets/logo-2.svg" alt="Cascades logo" title="Cascades">
			</a>
			<label for="projectTitle" class="visuallyHidden">Project Title</label>
			<input
				type="text"
				id="projectTitle"
				name="projectTitle"
				class="projectTitle"
				maxlength="50"
				v-model="projectReference.title"
				data-journey-id="CreateProjectTitle"
			>
			<button
				title="New"
				@click="createNewProject()"
				data-journey-id="CreateNew"
			>
				<span class="icon">add</span>
			</button>
			<button
				v-if="downloadAvailable"
				title="Download this project"
				@click="downloadProject()"
				data-journey-id="Download"
			>
				<span class="icon">cloud_download</span>
			</button>
			<div class="spacer"></div>
			<button
				title="Help"
				@click="dialogStore.open(dialogStore.Dialog.Help)"
				data-journey-id="CreateHelp"
			>
				<span class="icon">question_mark</span>
			</button>
			<a
				href="https://cascades.app/signup"
				target="_blank"
				title="Login"
				class="button accountButton"
				data-journey-id="CreateLogin"
			>
				<span class="icon">login</span>
			</a>
		</nav>

		<section class="editorPanels">
			<Editor
				:style="{
					width: verticalEditorArrangement ? '100%' : `${50 * projectReference.editorRatio}%`,
					height: verticalEditorArrangement ? `${50 * projectReference.editorRatio}%` : '100%',
					transition: resizing ? '0s ease width, 0s ease height' : '0.4s ease width, 0.4s ease height'
				}"
			/>
			<Resizer
				:horizontal="verticalEditorArrangement"
				:min="editorPanelRatio.min"
				:max="editorPanelRatio.max"
				:default="projectDefaults.editorRatio"
				@resizing="updateResizing"
				v-model="projectReference.editorRatio"
			/>
			<div
				:style="{
					width: verticalEditorArrangement ? '100%' : `${100 - 50 * projectReference.editorRatio}%`,
					height: verticalEditorArrangement ? `${100 - 50 * projectReference.editorRatio}%` : '100%',
					transition: resizing ? '0s ease width, 0s ease height' : '0.4s ease width, 0.4s ease height'
				}"
			>
				<CanvasPanel
					:style="{
						height: showBoxModel ? `${50 * projectReference.canvasRatio}%` : '100%',
						transition: resizing ? '0s ease height' : '0.4s ease height'
					}"
				>
					<template v-slot:settings>
						<label
							for="showBoxModel"
							class="canvasSetting"
							:title="showBoxModel ? 'Hide box model' : 'Show box model'"
						>
							<span class="icon outline">dialogs</span>
							<ToggleInput name="showBoxModel" v-model="showBoxModel" />
						</label>
					</template>
				</CanvasPanel>
				<Resizer
					v-show="showBoxModel"
					horizontal
					:min="canvasPanelRatio.min"
					:max="canvasPanelRatio.max"
					:default="projectDefaults.canvasRatio"
					@resizing="updateResizing"
					v-model="projectReference.canvasRatio"
				/>
				<BoxModelPanel
					:style="{
						height: showBoxModel ? `${100 - 50 * projectReference.canvasRatio}%` : '0%',
						opacity: showBoxModel ? '1' : '0',
						transition: resizing ? '0s ease height, 0s ease opacity' : '0.4s ease height, 0.4s ease opacity'
					}"
				/>
			</div>
		</section>
	</main>

	<EditorWizard />
</template>

<style scoped>
main {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 3.25rem calc(100vh - 3.25rem);
	grid-template-rows: 3.25rem calc(100dvh - 3.25rem);
	height: 100vh;
	height: 100dvh;
	background-color: #eee;
	user-select: none;
}
html.dark main {
	background-color: var(--color-background-dark);
}
main > section {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	max-width: unset;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

nav {
	grid-area: 1 / 1 / 2 / 3;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 1ch;
	margin: 0.5rem 0.5rem 0.25rem;
	padding: 0.7ch 1ch;
	border-radius: var(--border-radius-large);
}
nav .logo {
	display: block;
	width: 2rem;
	height: 2rem;
	margin-right: 1ch;
}
nav .projectTitle {
	width: initial;
	padding: 0.2em 0.75ch;
	font-size: 1rem;
	border-radius: var(--border-radius);
}
@media only screen and (max-width: 550px) {
	nav .projectTitle {
		max-width: 15ch;
	}
}
@media only screen and (max-width: 480px) {
	nav .projectTitle {
		display: none;
	}
}
nav > .spacer {
	margin-left: auto;
}

.editorPanels {
	overflow: hidden;
}

.canvasSetting {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5ch;
	height: 100%;
	padding: 0.25em;
	font-size: 0.8rem;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);
	cursor: pointer;
	
	& > span {
		font-size: 1.25rem;
	}

	&:has(:focus-visible) {
		outline: auto;
	}
}

@media only screen and (max-width: 700px) {
	main {
		grid-template-columns: 1fr;
		grid-template-rows: 3.25rem auto calc(100vh - 3.25rem);
		grid-template-rows: 3.25rem auto calc(100dvh - 3.25rem);
	}
	nav {
		grid-area: 1 / 1 / 2 / 2;
		margin: 0;
		border-radius: 0;
	}
	.editorPanels {
		flex-flow: column nowrap;
		height: 100%;
	}
	.editorContainer {
		padding: 0.25rem;
	}
	.canvasPanel {
		padding: 0.25rem;
	}
}

@media only screen and (max-width: 350px) {
	.saveButton > span:last-of-type {
		display: none;
	}
}
</style>
