<script setup lang="ts">
import { onMounted, ref } from 'vue';
import WizardTooltip from '@/components/WizardTooltip.vue';

const step = ref(0);
const complete = ref(Boolean(window.localStorage.getItem('editorWizardComplete')));
const highlighterPosition = ref({ top: '0px', left: '0px', width: '0px', height: '0px' });
const offlineSteps = [
	{
		message: 'Welcome to the Cascades editor!',
		secondary: 'Would you like a tutorial?',
		previous: 'Skip',
		next: 'Yes',
		element: '',
		icon: 'waving_hand'
	},
	{
		message: 'We\'ve loaded up a project for you.',
		secondary: 'Let\'s explore it...',
		previous: 'Back',
		next: 'Next',
		element: '',
		icon: 'mist'
	},
	{
		message: 'This is the code editor.',
		secondary: 'You can write CSS in here to your heart\'s content!',
		previous: 'Back',
		next: 'Next',
		element: '.editorPanels > .editorContainer'
	},
	{
		message: 'Use this menu to select the flavor of CSS you\'d like to write.',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: '.editorPanels > .editorContainer #cssFlavour'
	},
	{
		message: 'This is the canvas, where your creations come to life!',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: '.editorPanels > div:last-child'
	},
	{
		message: 'The box model is a handy tool for debugging your code.',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: '.editorPanels > div > .boxModel'
	},
	{
		message: 'These controls adjust the background color and zoom level of the canvas.',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: '.editorPanels > div > .canvasPanel .canvasSettings'
	},
	{
		message: 'You can use this box to rename your project.',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: '#projectTitle'
	},
	{
		message: 'If you\'re enjoying Cascades, you can create an account to unlock more features...',
		secondary: '',
		previous: 'Back',
		next: 'Next',
		element: 'nav .accountButton'
	},
	{
		message: 'That\'s it!',
		secondary: 'Have fun!',
		previous: 'Back',
		next: 'Done',
		element: '',
		icon: 'done'
	}
];
const steps = ref(offlineSteps);

function resetHighlighterPosition (): void {
	highlighterPosition.value.top = '0px';
	highlighterPosition.value.left = '0px';
	highlighterPosition.value.width = '0px';
	highlighterPosition.value.height = '0px';
}

function refreshHighlighterPosition (): void {
	const selector = steps.value[step.value].element;
	if (!selector) {
		resetHighlighterPosition();
		return;
	}
	const target = document.querySelector(selector)?.getBoundingClientRect();
	if (!target) {
		resetHighlighterPosition();
		return;
	}
	highlighterPosition.value.top = `${target.top}px`;
	highlighterPosition.value.left = `${target.left}px`;
	highlighterPosition.value.width = `${target.width}px`;
	highlighterPosition.value.height = `${target.height}px`;
}

function restart (): void {
	step.value = 0;
	refreshHighlighterPosition();
}

function previousStep (): void {
	if (step.value - 1 < 0) {
		complete.value = true;
		window.localStorage.setItem('editorWizardComplete', '1');
		return;
	}
	step.value--;
	refreshHighlighterPosition();
}

function nextStep (): void {
	if (step.value + 1 >= steps.value.length) {
		skip();
		return;
	}
	step.value++;
	refreshHighlighterPosition();
}

function skip (): void {
	complete.value = true;
	window.localStorage.setItem('editorWizardComplete', '1');
	resetHighlighterPosition();
}

function updateStepList (): void {
	step.value = 0;
	steps.value = offlineSteps;
	refreshHighlighterPosition();
}

onMounted(() => {
	updateStepList();
});
</script>

<template>
	<div
		class="editorWizard"
		:class="{ complete }"
		data-journey-id="EditorWizard"
	>
		<div class="highlighter" :style="highlighterPosition"></div>
		<WizardTooltip
			v-if="!complete"
			v-bind="steps[step]"
			:index="step - 1"
			@previous="previousStep()"
			@next="nextStep()"
		/>
		<div v-if="!complete" class="actions">
			<p>Tutorial actions</p>
			<div>
				<button
					title="Restart the tutorial"
					:disabled="step <= 0"
					@click="restart()"
				><span class="icon" aria-hidden="true">first_page</span></button>
				<button
					title="Previous Step"
					:disabled="step <= 0"
					@click="previousStep()"
				><span class="icon" aria-hidden="true">chevron_left</span></button>
				<button
					title="Next Step"
					:disabled="step >= steps.length - 1"
					@click="nextStep()"
				><span class="icon" aria-hidden="true">chevron_right</span></button>
				<button
					title="Exit the tutorial"
					@click="skip()"
				><span class="icon" aria-hidden="true">close</span></button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.editorWizard {
	position: fixed;
	z-index: 100;
	inset: 0;
	display: grid;
	place-items: center;
	background-color: #000b;
	transition: 0.5s ease background-color;
	overflow: hidden;
}
.editorWizard.complete {
	background-color: #0000;
	user-select: none;
	pointer-events: none;
}

.editorWizard .highlighter {
	position: absolute;
	display: block;
	border-radius: var(--border-radius);
	backdrop-filter: brightness(3.78);
}
.editorWizard.complete .highlighter {
	display: none;
}

.editorWizard .actions {
	position: absolute;
	bottom: 1ch;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 0 1.5ch;
	padding: 0.5ch;
	background-color: var(--color-background);
	border: 1px solid var(--color-border-light);
	border-radius: var(--border-radius);
	opacity: 0;
	animation: fadeIn 0.2s 0.05s ease forwards;
}

@keyframes fadeIn {
	0%   { opacity: 0; }
	100% { opacity: 1; }
}

.editorWizard .actions > p {
	margin: 0 1ch;
	font-size: small;
	opacity: 0.8;
}

.editorWizard .actions > div {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 1ch;
}

.editorWizard .actions button:disabled {
	opacity: 0.5;
}

@media only screen and (max-width: 700px) {
	.editorWizard .actions > p {
		display: none;
	}
}
</style>
