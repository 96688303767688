import { createApp } from 'vue';
import '@/css/root.css';
import App from '@/App.vue';
import { createPinia } from 'pinia';
import { registerSW } from 'virtual:pwa-register';
import { Session } from '@/modules/session';
import { useDialogStore } from '@/modules/stores/dialog';
import { useOfflineProjectStore } from '@/modules/stores/offlineProject';
import { useThemeStore } from '@/modules/stores/theme';

// Service worker.
const updateSW = registerSW({
	onNeedRefresh (): void {
		document.getElementById('refreshPrompt')?.classList.add('show');
		(document.querySelector('#refreshPrompt button.refresh') as HTMLButtonElement | undefined)?.addEventListener('click', () => { updateSW(); });
	},
	onOfflineReady (): void {
		console.info('Offline content ready.');
	},
});

// Version.
console.info('Cascades Version 0.14.0');

// Session logging (only run in production).
const productionMode = import.meta.env.PROD;
if (!productionMode) console.info('Cascades Dev Mode');
if (productionMode) {
	let session = new Session();
	session.begin();
	document.addEventListener('visibilitychange', () => {
		if (document.visibilityState === 'hidden') {
			session.end();
			const analyticsData = JSON.stringify(session.export());
			navigator.sendBeacon('/analytics', analyticsData);
		} else if (document.visibilityState === 'visible' && !session.active) {
			session = new Session();
			session.begin();
		}
	});
}

// Stores.
const app = createApp(App);
const pinia = createPinia();
export const dialogStore = useDialogStore(pinia);
export const offlineProject = useOfflineProjectStore(pinia);
export const theme = useThemeStore(pinia);

app.use(pinia);
app.mount('#app');
