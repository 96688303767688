<script setup lang="ts">
import { dialogStore } from '@/main';
import Create from '@/views/Create.vue';
import EditorWizard from '@/components/EditorWizard.vue';
import HelpMenu from '@/components/HelpMenu.vue';
import RefreshPrompt from '@/components/RefreshPrompt.vue';
import Notification from '@/components/Notification.vue';

// Allow dialogs to be closed with the `Escape` key.
window.addEventListener('keydown', (event) => {
	if (event.code === 'Escape') dialogStore.close();
});
</script>

<template>
	<Create />
	<EditorWizard
		v-if="dialogStore.current === dialogStore.Dialog.Wizard"
	/>
	<HelpMenu
		v-if="dialogStore.current === dialogStore.Dialog.Help"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Help)"
	/>
	<Notification
		v-if="dialogStore.current === dialogStore.Dialog.Notification"
		:message="dialogStore.dialogMessage"
		:model-value="true"
		@update:model-value="dialogStore.close(dialogStore.Dialog.Notification)"
	/>
	<RefreshPrompt />
</template>
