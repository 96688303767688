import type { Monaco } from '@monaco-editor/loader';
import type { editor } from 'monaco-editor';

export interface Editor {
	editor: editor.IStandaloneCodeEditor;
	model: editor.ITextModel;
	monaco: Monaco;
	remove: () => void;
	setContent: (content: string) => void;
	setLanguage: (language: string) => void;
	setTheme: (themeName: string) => void;
}

export async function getConfiguredEditor (
	element: HTMLElement,
	content: string,
	language: string,
	theme: string,
	options: Partial<editor.IStandaloneEditorConstructionOptions> = {}
): Promise<Editor> {
	const loader = await import('@monaco-editor/loader');
	const monaco = await loader.default.init();
	const model = monaco.editor.createModel(content, language);
	const config = getEditorConfig(model, theme, options);
	setEditorKeybinds(monaco);
	const editor = monaco.editor.create(element, config);
	const remove = () => {
		model.dispose();
		editor.dispose();
	};
	return {
		editor,
		model,
		monaco,
		remove,
		setContent: model.setValue.bind(model),
		setLanguage: monaco.editor.setModelLanguage.bind(monaco.editor, model),
		setTheme: monaco.editor.setTheme.bind(monaco.editor)
	};
}

export function getEditorConfig (
	model: editor.ITextModel,
	theme: string,
	options: Partial<editor.IStandaloneEditorConstructionOptions> = {}
): editor.IStandaloneEditorConstructionOptions {
	return {
		automaticLayout: true,
		colorDecorators: true,
		detectIndentation: false,
		dragAndDrop: true,
		emptySelectionClipboard: false,
		fixedOverflowWidgets: true,
		insertSpaces: false,
		lightbulb: {
			enabled: false
		},
		linkedEditing: true,
		model,
		padding: {
			top: 12
		},
		tabSize: 4,
		theme,
		wordWrap: 'on',
		wrappingIndent: 'same',
		...options
	};
}

export function setEditorKeybinds (monaco: Monaco): void {
	monaco.editor.addKeybindingRules([
		{
			keybinding: monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyD,
			command: 'editor.action.duplicateSelection'
		}
	]);
}
